"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
var TypeSocket = /** @class */ (function () {
    /**
     * Creates a new TypeSocket
     * @param url WebSocket server URL
     */
    function TypeSocket(url, options) {
        this.url = url;
        this.socket = null;
        this.retries = 0;
        this.options = {
            maxRetries: 5,
            retryOnClose: false,
            retryTime: 500,
        };
        this.events = {
            connected: new Set(),
            disconnected: new Set(),
            permanentlyDisconnected: new Set(),
            message: new Set(),
            invalidMessage: new Set(),
            rawMessage: new Set(),
        };
        if (options) {
            this.options = __assign(__assign({}, this.options), options);
        }
    }
    /**
     * Connects to the server.
     *
     * Will automatically retry on failure.
     */
    TypeSocket.prototype.connect = function () {
        var _this = this;
        if (this.socket) {
            try {
                this.socket.close();
                this.disconnected();
            }
            catch (_a) { }
        }
        this.socket = new WebSocket(this.url);
        this.socket.onopen = function () {
            _this.connected();
        };
        this.socket.onclose = function (e) {
            if (e.code === 1000 || (_this.options.retryOnClose && _this.socket)) {
                _this.reconnectAfterTime(_this.options.retryTime);
            }
            else {
                _this.disconnected();
                _this.permanentlyDisconnected();
            }
        };
        this.socket.onmessage = function (e) {
            _this.message(e.data);
        };
        this.socket.onerror = function () {
            _this.disconnected();
            _this.socket = null;
            _this.reconnectAfterTime(_this.options.retryTime);
        };
    };
    /**
     * Disconnects the connection.
     *
     * When called, TypeSocket will stop retrying, the WebSocket will be closed and both disconnected and permanentlyDisconnected events will be called.
     */
    TypeSocket.prototype.disconnect = function () {
        if (this.socket) {
            try {
                this.socket.onclose = null;
                this.socket.onerror = null;
                this.socket.close();
                this.disconnected();
                this.permanentlyDisconnected();
            }
            catch (_a) { }
        }
    };
    /**
     * Sends a JavaScript object of type T to the server.
     * @param data JS object.
     */
    TypeSocket.prototype.send = function (data) {
        if (!this.socket)
            return;
        this.socket.send(JSON.stringify(data));
    };
    /**
     * Sends raw data over the socket.
     * @param data Raw data.
     */
    TypeSocket.prototype.sendRaw = function (data) {
        if (!this.socket)
            return;
        this.socket.send(data);
    };
    Object.defineProperty(TypeSocket.prototype, "readyState", {
        /**
         * Ready state of the socket.
         */
        get: function () {
            return this.socket ? this.socket.readyState : 0;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Adds a listener for a given event.
     * @param eventType Event type.
     * @param listener Listener function.
     */
    TypeSocket.prototype.on = function (eventType, listener) {
        this.events[eventType].add(listener);
    };
    /**
     * Removes a listener for a given event.
     * @param eventType Event type.
     * @param listener Listener function.
     */
    TypeSocket.prototype.off = function (eventType, listener) {
        this.events[eventType].delete(listener);
    };
    TypeSocket.prototype.emit = function (eventType) {
        var e_1, _a;
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        try {
            for (var _b = __values(this.events[eventType]), _c = _b.next(); !_c.done; _c = _b.next()) {
                var listener = _c.value;
                listener.apply(this, args);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    TypeSocket.prototype.reconnectAfterTime = function (time) {
        var _this = this;
        if (time === void 0) { time = 500; }
        if (this.socket) {
            this.socket.close();
        }
        this.socket = null;
        this.disconnected();
        setTimeout(function () {
            _this.reconnect();
        }, time);
    };
    TypeSocket.prototype.reconnect = function () {
        this.retries++;
        if (this.options.maxRetries && this.retries > this.options.maxRetries) {
            this.disconnected();
            this.permanentlyDisconnected();
            return;
        }
        this.connect();
    };
    TypeSocket.prototype.connected = function () {
        this.retries = 0;
        this.emit('connected');
    };
    TypeSocket.prototype.disconnected = function () {
        this.emit('disconnected');
    };
    TypeSocket.prototype.permanentlyDisconnected = function () {
        this.emit('permanentlyDisconnected');
    };
    TypeSocket.prototype.message = function (data) {
        this.emit('rawMessage', data);
        if (typeof data === 'string') {
            try {
                var json = JSON.parse(data);
                if (json) {
                    this.emit('message', json);
                    return;
                }
            }
            catch (_a) { }
        }
        this.emit('invalidMessage', data);
    };
    return TypeSocket;
}());
exports.TypeSocket = TypeSocket;
